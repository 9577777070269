<template>
  <div>
    <v-card max-width="600px">
      <v-form ref="form">
        <v-tabs v-model="active" color="blue lighten-2" ref="tabs" id="nav">
          <v-tab ref="login"> Login </v-tab>
          <v-tab> Sign Up </v-tab>
          <v-tab> Reset </v-tab>
          <v-tab-item :key="1">
            <v-card>
              <v-card-title> Login </v-card-title>
              <v-spacer />
              <v-card-text>
                Don't have an account yet ?
                <a @click="active = 1">Sign up here.</a>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-text-field
                        v-model="email"
                        :rules="[rules.required, rules.email]"
                        label="E-mail"
                        v-on:keyup.enter="login()"
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        ref="pwd-input"
                        v-model="password"
                        :append-icon="showpwd ? 'visibility' : 'visibility_off'"
                        :rules="[rules.required, rules.min]"
                        :type="showpwd ? 'text' : 'password'"
                        :error-messages="errorMessages"
                        name="input-10-1"
                        label="Password"
                        hint="At least 8 characters"
                        counter
                        @click:append="showpwd = !showpwd"
                        v-on:keyup.enter="login()"
                        outlined
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
                <a @click="active = 2">Forgot password ?</a>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn outlined color="blue darken-1" text @click="login()"
                  >Sign In</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item :key="2">
            <v-card v-if="!signupMessage">
              <v-card-title> Create an account. </v-card-title>
              <v-spacer/>
              <v-card-text>
                Already have an account ?<a @click="active = 0"> Login here.</a>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-text-field
                        outlined
                        v-model="email"
                        :rules="[
                          rules.required,
                          rules.email,
                          rules.invalidDomain,
                        ]"
                        label="E-mail"
                        ref="email"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        outlined
                        v-model="password1"
                        :append-icon="showpwd ? 'visibility' : 'visibility_off'"
                        :rules="[rules.required, rules.min]"
                        :type="showpwd ? 'text' : 'password'"
                        name="password1"
                        label="Password"
                        hint="At least 8 characters"
                        counter
                        @click:append="showpwd = !showpwd"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        outlined
                        v-model="password2"
                        :append-icon="showpwd ? 'visibility' : 'visibility_off'"
                        :rules="pwdConfirmationRules"
                        :type="showpwd ? 'text' : 'password'"
                        name="password2"
                        label="Retype password"
                        hint="At least 8 characters"
                        counter
                        @click:append="showpwd = !showpwd"
                        v-on:keyup.enter="signup()"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn outlined color="blue darken-1" text @click="signup()"
                  >Validate</v-btn
                >
              </v-card-actions>
            </v-card>
            <v-card v-else>
              <v-card-title>
                {{ signupMessage }}
              </v-card-title>
              <v-spacer />
              <v-card-text>
                {{ signupSubMessage }}
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="blue darken-1"
                  outlined
                  text
                  @click="signupMessage = null"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item :key="3">
            <v-card v-if="$route.query.passwordchange">
              <v-card-title> Please enter your new password. </v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-text-field
                        outlined
                        v-model="password1"
                        :append-icon="showpwd ? 'visibility' : 'visibility_off'"
                        :rules="[rules.required, rules.min]"
                        :type="showpwd ? 'text' : 'password'"
                        name="password1"
                        label="Password"
                        hint="At least 8 characters"
                        counter
                        @click:append="showpwd = !showpwd"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        outlined
                        v-model="password2"
                        :append-icon="showpwd ? 'visibility' : 'visibility_off'"
                        :rules="pwdConfirmationRules"
                        :type="showpwd ? 'text' : 'password'"
                        name="password2"
                        label="Retype password"
                        hint="At least 8 characters"
                        counter
                        @click:append="showpwd = !showpwd"
                        v-on:keyup.enter="resetPasswordConfirm()"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="resetPasswordConfirm()"
                  outlined
                  >Validate</v-btn
                >
              </v-card-actions>
            </v-card>
            <v-card v-else>
              <v-card-title> Reset password </v-card-title>
              <v-spacer />
              <v-card-text>
                Please enter your email address, we'll send you a link to reset
                your password.
              </v-card-text>
              <v-spacer />
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-text-field
                        outlined
                        v-model="email"
                        :rules="[rules.required, rules.email]"
                        label="E-mail"
                        v-on:keyup.enter="resetPassword()"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  color="blue darken-1"
                  text
                  @click="resetPassword()"
                  >Validate</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-form>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="6000"
      :top="true"
      :vertical="false"
      >{{ snackbarText }}</v-snackbar
    >
  </div>
</template>

<style>
.container {
  padding: 15px 0;
}
.v-card {
  min-height: 436px;
}
.v-card {
  min-height: 436px;
  background-color: white;
}
.v-tabs__item--active {
  background-color: white !important;
  color: black !important;
}
.v-tabs__container--icons-and-text {
  height: 4rem;
}
.v-tabs-slider-wrapper {
  display: none;
}
.theme--light.v-tabs > .v-tabs-bar {
  background-color: #93b7cb !important;
  color: #000;
  text-shadow: none;
}
.v-tab:not(.v-tab--active) {
  color: #fff;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon {
  color: #fff;
}
.v-tab.v-tab--active {
  color: black;
  background-color: #fff;
}
</style>

<script>
import LOGIN from "@/graphql/mutations/login.gql";
import REGISTER from "@/graphql/mutations/register.gql";
import RESETPASSWORDCONFIRM from "@/graphql/mutations/resetPasswordConfirm.gql";
import RESETPASSWORD from "@/graphql/mutations/resetPassword.gql";
import { onLogin } from "@/apollo.js";
import { mapActions } from "vuex";

export default {
  data: function () {
    return {
      snackbar: false,
      snackbarColor: "success",
      snackbarText: "",
      signupMessage: "",
      signupSubMessage: "",
      active: 0, // set login as default active tab
      showpwd: false,
      password: "",
      errorMessages: "",
      password1: "",
      password2: "",
      email: "",
      validDomain: true,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return (value && pattern.test(value)) || "Invalid e-mail.";
        },
        invalidDomain: (v) =>
          this.validDomain ||
          this.$refs.email.value.split("@")[1] +
            " email addresses are not allowed",
      },
    };
  },
  beforeMount() {
    if (this.$route.query.passwordchange) {
      this.active = 2;
    }
    if (this.$route.query.login) {
      this.active = 0;
    }
  },
  computed: {
    pwdConfirmationRules() {
      return [
        () => this.password1 === this.password2 || "Passwords do not match",
        (v) => !!v || "Please retype password",
      ];
    },
  },
  methods: {
    login() {
      const email = this.email.trim();
      const password = this.password;
      this.$apollo
        .mutate({
          mutation: LOGIN,
          variables: {
            email,
            password,
          },
        })
        .then((result) => {
          const client = this.$apollo.getClient();
          onLogin(client, result.data.login.token);
          this.setAuthUser(email);
          this.$router.push("main");
        })
        .catch((e) => {
          this.errorMessages = ["Email and password do not match"];
        });
    },
    signup() {
      const email = this.email.trim();
      const password = this.password1;
      this.$apollo
        .mutate({
          mutation: REGISTER,
          variables: {
            email,
            password,
          },
        })
        .then((result) => {
          if (result.data.register.success === false) {
            if (result.data.register.errors[0] == "emailAlreadyExists") {
              this.signupMessage =
                "An account with this email address already exists.";
              this.signupSubMessage =
                "We've just sent you an email to reset your password.";
            }
            if (result.data.register.errors[0] == "invalidDomain") {
              this.validDomain = false;
              this.$refs.email.validate();
              this.validDomain = true;
            }
          } else {
            this.signupMessage = "Please check your emails";
            this.signupSubMessage =
              "We've just sent you an email with instructions to finish your registration.";
          }
        })
        .catch((e) => {
          this.$raven.captureException(e);
          this.signupMessage = "Server error, please retry later.";
          this.signupSubMessage = `We get informed when this error occurs and we must be working
              on it, but if it still not working in few hours, feel free to
               contact us.`;
        });
    },
    resetPassword() {
      const email = this.email.trim();
      this.$apollo
        .mutate({
          mutation: RESETPASSWORD,
          variables: {
            email,
          },
        })
        .then((result) => {
          if (result.data.resetPassword.success) {
            this.signupMessage = "Please check your emails";
            this.signupSubMessage = `We just sent you a link to reset your password`;
            this.active = 1;
          } else {
            this.snackbarText = "Sorry we don't have any email like this.";
            this.snackbarColor = "warning";
            this.snackbar = true;
          }
        })
        .catch((e) => {
          window.alert("Something get wrong, please try again.");
          this.$raven.captureException(e)
        });
    },
    resetPasswordConfirm() {
      const token = this.$route.query.passwordchange;
      const password = this.password1;
      this.$apollo
        .mutate({
          mutation: RESETPASSWORDCONFIRM,
          variables: {
            token,
            password,
          },
        })
        .then((result) => {
          if (result.data.resetPasswordConfirm.success) {
            this.snackbarColor = "info";
            this.snackbarText = "Password changed";
            this.snackbar = true;
            setTimeout(() => {
              this.active = 0;
            }, 1500);
          } else {
            this.snackbarColor = "error";
            this.snackbarText = "Error, please try again";
            this.snackbar = true;
            setTimeout(() => {
              this.$router.push("/");
            }, 1500);
          }
        })
        .catch((e) => {
          this.$raven.captureException(e);
        });
    },
    ...mapActions(["setAuthUser"]),
  },
};
</script>